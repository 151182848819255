// logout.js
'use strict';
import { clearState } from '../resource/persist.js';
import { LOGOUT, PERSIST_NOW } from '../ActionTypes.js';
import { processMixpanelApiCall } from '../resource/mixpanel.js';
import { defaultIntercomSetting } from '../resource/intercomScript.js';
import getMeData from '../selector/getMeData.js';
import * as MessageTypes from '../serviceWorker/AppMessageTypes.js';
import { sendMessageToSW } from '../serviceWorker/helpers.js';
import { replace } from '../action/navigationAction.js';
import resetConfigurations from '../action/resetConfigurations.js';
import { setMixpanelViewIdMeData } from '../resource/getMixpanelViewId.js';

const hasCachesApi = typeof caches !== 'undefined';
const privateCacheKeys = ['message-resource', 'general-api'];

/**
 * Logout
 * @kind action
 * @param {string} [{pathname}='/'] - redirect path after logout
 * @param {string} [{search}='/'] - redirect search after logout
 * @param {boolean} [{isFromServiceWorker}=false] - if from service worker
 * @return {Promise} Action promise
 */
const logout =
  ({ pathname = '/', search = '', isFromServiceWorker = false } = {}) =>
  async (dispatch, getState) => {
    const token = getMeData(getState(), 'token');
    if (!token) {
      return dispatch({ type: '' });
    }

    // Delete FCM token
    const fcmToken = getMeData(getState(), 'fcmToken');
    if (fcmToken) {
      const { deleteFcmToken } = await import('../resource/fcm.js');
      await deleteFcmToken();
    }

    // Reset ServiceWorker dynamic caches.
    if (hasCachesApi) {
      caches
        .keys()
        .then(keys =>
          Promise.all(
            keys
              .filter(key => privateCacheKeys.includes(key))
              .map(key => caches.delete(key))
          )
        )
        .catch(() => null);
    }
    // for latter might use
    clearState();
    dispatch({ type: LOGOUT });

    setMixpanelViewIdMeData({
      meId: undefined,
      meUsername: undefined,
    });

    if (window.Intercom) {
      window.Intercom('shutdown');
      window.Intercom('boot', defaultIntercomSetting);

      const clientId = getMeData(getState(), 'clientId');
      window.Intercom('update', { client_id: clientId });
    }
    dispatch(resetConfigurations());

    if (!isFromServiceWorker) {
      // mixpanel reset and replace the device id to client id
      processMixpanelApiCall({ apiNames: ['reset'] });
      const clientId = getMeData(getState(), 'clientId');
      processMixpanelApiCall({
        apiNames: ['register'],
        args: [{ $device_id: clientId }],
      });

      await dispatch({ type: PERSIST_NOW });
      // only local logout action should post message to SW then clear event, channel.
      // if from SW means some tab logout action was called.
      sendMessageToSW({ type: MessageTypes.LOGOUT });
    }
    return dispatch(replace({ pathname, search }));
  };

export default logout;
